.lineParagraph {
    margin: 0px;
    width: 75%;


    color: 'black';
    font-size: 17px;
    line-height: 28px;
    font-family: "Verdana";
    padding-left: 5px;
}

.lineParagraph2 {
    margin: 0px;
    width: 75%;


    color: 'black';
    font-size: 17px;
    line-height: 28px;
    font-family: "Verdana";
    padding-left: 5px;
}

.gotAnnotation {
    background-color:#fff2cc;
}

.gotAnnotationSelected {
    background-color:#f0b400;
}

.clickable:hover {
    cursor: pointer;
}

.emptyParagraph {
    margin: 0px;
    width: 75%;


    color: 'black';
    font-size: 17px;
    line-height: 28px;
    font-family: "Verdana";
    padding-left: 5px;
}


.selected {
    background-color: #5555aa;
    color: white;
    margin: 0px;
    width: 75%;

    color: 'black';
    font-size: 17px;
    line-height: 28px;
    font-family: "Verdana";
    padding-left: 5px; 
}


.lineParagraph:hover {
    background-color: #aaaaaa;
    cursor: pointer;
}

.selected:hover {
    background-color: #2b2b55;
    cursor: pointer;

}
